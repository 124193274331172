import React, { Suspense, lazy, useEffect } from "react";
import "./App.css";
import "./style.scss";
import Load from "./components/Load";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useLoginStore } from "./Views/Auth/store";
const Index = lazy(() => import("./Views/Pages/Index"));
const Login = lazy(() => import("./Views/Auth/Login"));
const SOP = lazy(() => import("./Views/Pages/HRD/SOP/SOP"));
const CV = lazy(() => import("./Views/Pages/HRD/CV/CV"));
const Kontrak = lazy(() => import("./Views/Pages/HRD/Kontrak/Kontrak"));
const AddCv = lazy(() => import("./Views/Pages/HRD/CV/AddCv"));
const Agenda = lazy(() => import("./Views/Pages/PM/Agenda"));
const Program = lazy(() => import("./Views/Pages/PM/Program/Program"));
const AddProgram = lazy(() => import("./Views/Pages/PM/Program/AddProgram"));
const AddKontrak = lazy(() => import("./Views/Pages/HRD/Kontrak/AddKontrak"));
const Notulensi = lazy(() =>
  import("./Views/Pages/Administrasi/Notulensi/Notulensi")
);
const AddNotulensi = lazy(() =>
  import("./Views/Pages/Administrasi/Notulensi/AddNotulensi")
);
const Keluar = lazy(() => import("./Views/Pages/Administrasi/Keluar/Keluar"));
const AddMasuk = lazy(() =>
  import("./Views/Pages/Administrasi/Masuk/AddMasuk")
);
const AddKeluar = lazy(() =>
  import("./Views/Pages/Administrasi/Keluar/AddKeluar")
);
const Masuk = lazy(() => import("./Views/Pages/Administrasi/Masuk/Masuk"));
const Kegiatan = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/LaporanKegiatan/Kegiatan")
);
const AddKegiatan = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/LaporanKegiatan/AddLaporanKegiatan")
);
const Monitoring = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/LaporanMonitoring/Monitoring")
);
const AddMonitoring = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/LaporanMonitoring/AddMonitoring")
);
const Evaluasi = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/Evaluasi/Evaluasi")
);
const AddEvaluasi = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/Evaluasi/AddEvaluasi")
);
const KeuanganProgram = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/KeuanganProgram/Keuangan")
);
const AddKeuangan = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/KeuanganProgram/AddKeuangan")
);
const Staff = lazy(() => import("./Views/Pages/PM/Staff/Staff"));
const AddKegiatanEksternal = lazy(() =>
  import(
    "./Views/Pages/PM/Staff/Laporan/KegiatanEksternal/AddLaporanKegiatanEksternal"
  )
);
const KegiatanEksternal = lazy(() =>
  import("./Views/Pages/PM/Staff/Laporan/KegiatanEksternal/KegiatanEksternal")
);
const Bulanan = lazy(() =>
  import("./Views/Pages/PM/Staff/Laporan/Bulanan/Bulanan")
);
const AddBulanan = lazy(() =>
  import("./Views/Pages/PM/Staff/Laporan/Bulanan/AddBulanan")
);
const AddSop = lazy(() => import("./Views/Pages/HRD/SOP/AddSop"));
const DetailProgram = lazy(() =>
  import("./Views/Pages/PM/Program/DetailProgram")
);
const EditProgram = lazy(() => import("./Views/Pages/PM/Program/EditProgram"));
const DetailKegiatan = lazy(() =>
  import(
    "./Views/Pages/PM/Program/Laporan/LaporanKegiatan/DetailLaporanKegiatan"
  )
);
const EditKegiatan = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/LaporanKegiatan/EditLaporanKegiatan")
);
const DetailMonitoring = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/LaporanMonitoring/DetailMonitoring")
);
const EditMonitoring = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/LaporanMonitoring/EditMonitoring")
);
const DetailEvaluasi = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/Evaluasi/DetailEvaluasi")
);
const EditEvaluasi = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/Evaluasi/EditEvaluasi")
);
const DetailKeuangan = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/KeuanganProgram/DetailKeuangan")
);
const EditKeuangan = lazy(() =>
  import("./Views/Pages/PM/Program/Laporan/KeuanganProgram/EditKeuangan")
);
const DetailKegiatanEksternal = lazy(() =>
  import(
    "./Views/Pages/PM/Staff/Laporan/KegiatanEksternal/DetailLaporanKegiatanEksternal"
  )
);
const EditKegiatanEksternal = lazy(() =>
  import(
    "./Views/Pages/PM/Staff/Laporan/KegiatanEksternal/EditLaporanKegiatanEksternal"
  )
);
const DetailBulanan = lazy(() =>
  import("./Views/Pages/PM/Staff/Laporan/Bulanan/DetailBulanan")
);
const EditBulanan = lazy(() =>
  import("./Views/Pages/PM/Staff/Laporan/Bulanan/EditBulanan")
);
const Publikasi = lazy(() =>
  import("./Views/Pages/Logistik/Publikasi/Publikasi")
);
const Merchandise = lazy(() =>
  import("./Views/Pages/Logistik/Merchandise/Merchandise")
);
const AddMerchandise = lazy(() =>
  import("./Views/Pages/Logistik/Merchandise/AddMerchandise")
);
const EditMerchandise = lazy(() =>
  import("./Views/Pages/Logistik/Merchandise/EditMerchandise")
);
const DetailMerchandise = lazy(() =>
  import("./Views/Pages/Logistik/Merchandise/DetailMerchandise")
);
const AddPublikasi = lazy(() =>
  import("./Views/Pages/Logistik/Publikasi/AddPublikasi")
);
const EditPublikasi = lazy(() =>
  import("./Views/Pages/Logistik/Publikasi/EditPublikasi")
);
const DetailPublikasi = lazy(() =>
  import("./Views/Pages/Logistik/Publikasi/DetailPublikasi")
);
const Pengguna = lazy(() => import("./Views/Pages/Pengguna/Pengguna"));
const AddPengguna = lazy(() => import("./Views/Pages/Pengguna/AddPengguna"));
const EditPengguna = lazy(() => import("./Views/Pages/Pengguna/EditPengguna"));

const menus = [
  { el: <SOP />, url: "hrd/sop" },
  { el: <AddSop />, url: "hrd/sop/add" },
  { el: <CV />, url: "hrd/cv" },
  { el: <AddCv />, url: "hrd/cv/add" },
  { el: <Kontrak />, url: "hrd/kontrak" },
  {
    el: <AddKontrak />,
    url: "hrd/kontrak/add",
  },
  { el: <Agenda />, url: "project-management/agenda" },
  { el: <Program />, url: "project-management/program" },
  { el: <AddProgram />, url: "project-management/program/add" },
  { el: <DetailProgram />, url: "project-management/program/detail/:id" },
  { el: <EditProgram />, url: "project-management/program/edit/:id" },
  { el: <Staff />, url: "project-management/staff" },
  { el: <Notulensi />, url: "e-administrasi/notulensi" },
  { el: <AddNotulensi />, url: "e-administrasi/notulensi/add" },
  { el: <Masuk />, url: "e-administrasi/surat-masuk" },
  { el: <AddMasuk />, url: "e-administrasi/surat-masuk/add" },
  { el: <Keluar />, url: "e-administrasi/surat-keluar" },
  { el: <AddKeluar />, url: "e-administrasi/surat-keluar/add" },
  { el: <Publikasi />, url: "/loged/logistik/publikasi" },
  { el: <AddPublikasi />, url: "/loged/logistik/publikasi/add" },
  { el: <EditPublikasi />, url: "/loged/logistik/publikasi/edit/:id" },
  { el: <DetailPublikasi />, url: "/loged/logistik/publikasi/detail/:id" },
  { el: <Merchandise />, url: "/loged/logistik/merchandise" },
  { el: <AddMerchandise />, url: "/loged/logistik/merchandise/add" },
  { el: <DetailMerchandise />, url: "/loged/logistik/merchandise/detail/:id" },
  { el: <EditMerchandise />, url: "/loged/logistik/merchandise/edit/:id" },
  {
    el: <RoleComp el={<Pengguna />} role={["admin"]} />,
    url: "/loged/pengguna",
  },
  {
    el: <RoleComp el={<AddPengguna />} role={["admin"]} />,
    url: "/loged/pengguna/add",
  },
  {
    el: <RoleComp el={<EditPengguna />} role={["admin"]} />,
    url: "/loged/pengguna/edit/:id",
  },
];

const laps = [
  { el: <Kegiatan />, url: "laporan-kegiatan" },
  { el: <AddKegiatan />, url: "laporan-kegiatan/add" },
  { el: <DetailKegiatan />, url: "laporan-kegiatan/detail/:id" },
  { el: <EditKegiatan />, url: "laporan-kegiatan/edit/:id" },
  { el: <Monitoring />, url: "laporan-monitoring" },
  { el: <AddMonitoring />, url: "laporan-monitoring/add" },
  { el: <DetailMonitoring />, url: "laporan-monitoring/detail/:id" },
  { el: <EditMonitoring />, url: "laporan-monitoring/edit/:id" },
  { el: <Evaluasi />, url: "laporan-evaluasi" },
  { el: <AddEvaluasi />, url: "laporan-evaluasi/add" },
  { el: <DetailEvaluasi />, url: "laporan-evaluasi/detail/:id" },
  { el: <EditEvaluasi />, url: "laporan-evaluasi/edit/:id" },
  { el: <KeuanganProgram />, url: "laporan-keuangan" },
  { el: <AddKeuangan />, url: "laporan-keuangan/add" },
  { el: <DetailKeuangan />, url: "laporan-keuangan/detail/:id" },
  { el: <EditKeuangan />, url: "laporan-keuangan/edit/:id" },
];

function App() {
  const [state, action] = useLoginStore();

  useEffect(() => {
    action.checkUser();
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Load />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/loged"
            element={
              <RequireAuth>
                <Index />
              </RequireAuth>
            }
          >
            {menus.map((e, i) => (
              <Route key={i} path={e.url} element={e.el} />
            ))}
            {laps.map((lap, i) => (
              <Route
                key={i}
                path={`project-management/program/list/:program/${lap.url}`}
                element={lap.el}
              />
            ))}
            <Route
              path={`project-management/staff/laporan-kegiatan-eksternal`}
              element={<KegiatanEksternal />}
            />
            <Route
              path={`project-management/staff/laporan-kegiatan-eksternal/add`}
              element={<AddKegiatanEksternal />}
            />
            <Route
              path={`project-management/staff/laporan-kegiatan-eksternal/detail/:id`}
              element={<DetailKegiatanEksternal />}
            />
            <Route
              path={`project-management/staff/laporan-kegiatan-eksternal/edit/:id`}
              element={<EditKegiatanEksternal />}
            />
            <Route
              path={`project-management/staff/laporan-bulanan`}
              element={<Bulanan />}
            />
            <Route
              path={`project-management/staff/laporan-bulanan/add`}
              element={<AddBulanan />}
            />
            <Route
              path={`project-management/staff/laporan-bulanan/detail/:id`}
              element={<DetailBulanan />}
            />
            <Route
              path={`project-management/staff/laporan-bulanan/edit/:id`}
              element={<EditBulanan />}
            />
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

function RequireAuth({ children }) {
  const [state, action] = useLoginStore();
  const location = useLocation();

  return state.isAuthed === true ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

function RoleComp({ el, role }) {
  const [state, action] = useLoginStore();

  return role.includes(state.profile.role) ? (
    el
  ) : (
    <Navigate to="/loged/agenda" replace />
  );
}

export default App;
